<template>
	<Toast :baseZIndex="1100" />
	<div class="p-grid" >
		<div class="p-col-12 p-md-6">
			<div class="card">
				<h5 style="margin-left: 5px;">邮箱</h5>
				<div class="p-formgroup-inline">
					<div class="p-col-12 p-md-6">
						<div class="p-inputgroup">
							<InputText  :value='user_info.base_info.em' placeholder="邮箱"/>
							<Button icon='pi pi-pencil' @click="changeEmail('em')" />
						</div>
					</div>
					<div class="p-col-12 p-md-6">
						<div class="p-inputgroup">
							<InputText  :value='user_info.base_info.bk_em' placeholder="备用邮箱"/>
							<Button icon='pi pi-pencil' @click="changeEmail('bk_em')" />
						</div>
					</div>
				</div>
				<h5 style="margin-left: 5px;">专业</h5>
				<div class="p-formgroup-inline">
					<div class="p-col-12 p-md-12">
						<div class="p-inputgroup">
							<InputText  :value='user_major' placeholder="专业"/>
							<!-- <Button icon='pi pi-wallet' class='p-button-success' label='充值' /> -->
							<Button icon='pi pi-pencil' @click="showEditMajorDialog=true" label='修改' />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="p-col-12 p-md-6">
			<div class="card">
				<h5 style="margin-left: 5px;">积分</h5>
				<div class="p-formgroup-inline">
					<div class="p-col-12 p-md-12">
						<div class="p-inputgroup">
							<InputText  :value='user_info.account_info.crd' placeholder="积分"/>
							<!-- <Button icon='pi pi-wallet' class='p-button-success' label='充值' /> -->
							<Button icon='pi pi-users' label='邀新用户赚积分' />
						</div>
					</div>
				</div>
				<h5 style="margin-left: 5px;">邀请链接</h5>
				<div class="p-formgroup-inline">
					<div class="p-col-12 p-md-12">
						<div class="p-inputgroup">
							<InputText id='invote_link' :value='user_info.account_info.iv_path' placeholder="邀请链接"/>
							<Button icon='pi pi-book' @click='copyLink' label='复制' />
						</div>
					</div>
				</div>
<!-- 				<h5 style="margin-left: 5px;">邀请码	<i class="pi pi-sign-in down-img-button" @click="downloadIvQr(user_info.account_info.iv_qr)"></i></h5>
				<img :src="user_info.account_info.iv_qr" width="200" alt="invote qrcode" /> -->
			</div>
		</div>
		
		<Dialog v-model:visible="show_change_email" :style="{width: '270px'}" header="更改邮箱" :modal="true" class="p-fluid">
			<div class="login-container">
				<div class="input-container" style="margin-top: 10;">
					<!-- <label class='login-lable' for="user_email">邮箱</label> -->
				<InputText id="user_email" :value='email'   v-on:keyup.enter="getVerifyCode" style="margin-top: 10px;" type="text"
					placeholder="邮箱" />
				</div>
				<div class="input-container">
					<!-- <label class='login-lable' for="vericode">验证码</label> -->
					<div style="display: flex;flex-direction: row;justify-content:space-between;margin-top: 10px;">
<!-- 						<InputText type="text"  :value='vecode' v-on:keyup.enter="login" id='vericode' style="width: 160px;" placeholder="验证码" />
						<Button style="width: 60px;" v-show='vecode_time<=0' @click='getVerifyCode' label="获取"></Button>
						<Button style="width: 60px;" class="p-button-secondary" v-show='vecode_time>0' disabled :label="vecode_time+' S'"></Button> -->
						
						<InputText type="text" :disabled='!show'  :value='vecode' v-on:keyup.enter="login" id='vericode' style="width: 160px;" placeholder="验证码" />
						<Button style="width: 60px;" v-show='show' @click='getVerifyCode' label="获取"></Button>
						<Button style="width: 60px;" class="p-button-secondary" v-show='!show' disabled :label="vecode_time+' S'"></Button>
					</div>
				</div>
				<Button  @click='login' label="更换" style="margin-top: 20px;"></Button>
			</div>
		</Dialog>
		<!-- 标注多篇文章的主题 -->
		<Dialog v-model:visible="showEditMajorDialog" :style="{width: '450px'}" header="修改所属学科" :modal="true">
			<div class="confirmation-content" width='100%'>
				<WordFilterInput ref='majorSelected' :change_tmp_major_copy='true' :filter_input_id_copy="'user_major_in_account'" v-if='majors.length!==0' :list_copy='majors'
					:selected_words_copy='this_selected_major_name' :op_status_copy='1' />
			</div>
			<template #footer>
				<Button label="取消" icon="pi pi-times" class="p-button-text"
					@click="showEditMajorDialog = false" />
				<Button label="保存" icon="pi pi-check" class="p-button-text" @click="saveMajor" />
			</template>
		</Dialog>
	</div>
</template>
<script>
	import NetWorkService from '../../service/NetWorkService.js';
	import WordFilterInput from '../userComponents/WordFilterInput.vue';
	import MajorService from '../../service/MajorService.js';
	export default {
		data() {
			return {
				this_selected_major_name: this.$appState.user_info.major,
				major: null,
				majors: [],
				showEditMajorDialog:false,
				vecode_time:0,
				change_email_type:'',
				show_change_email:false,
				scence_id:0,
				show: true,
				count: '20',
				email:null,
				vecode:'',
				timer: null,
				'iv_cd':'http://localhost:8080/#/readPaper',
				processing :false,
				user_info:{
					'base_info':{},
					'account_info':{},
				},
			}
		},
		majorService: null,
		created() {
			this.networkService = new NetWorkService();
			this.majorService = new MajorService();
		},
		components: {
			'WordFilterInput': WordFilterInput,
		},
		mounted() {
			this.getUserInfo();
			this.majorService.getMajorsNameAndId().then(data => {
				this.majors = data;
			});
		},
		computed:{
			user_major(){
				return this.$appState.user_info.major;
			}
		},
		methods: {
			async saveMajor(){
				//将专业倒序并组织
				this.$refs.majorSelected.recorrectUserMajor();
				var str_majors = this.$refs.majorSelected.selected_words;
				// var words = str_majors.split(' ');
								
				// var old_mjid = words.reverse();
				// var or_mjid=[];
				// for (var i=0;i<old_mjid.length;i++){
				// 	if (old_mjid[i]!=''){
				// 		or_mjid.push(old_mjid[i])
				// 	}
				// }
				str_majors.replace(' ','')
				var or_mjid=[str_majors]
				
				// console.log('专业：',or_mjid);
				
				var data = new URLSearchParams();
				data.append("new_major",or_mjid[0]);
				var url = '/member/change-major';
				var resp = await this.networkService.postService(url, data);
				if(resp.code==200){
					this.$appState.user_info.major=or_mjid[0];
					this.showEditMajorDialog=false;
					var _this=this;
					setTimeout(() => {
						//uni.showToast({title: '验证码已发送',icon:"none"});
						_this.getUserInfo();
					}, 1000)
				}
			},
			//下载图片
			downloadIvQr(url) {
				var names=url.split('/');
				var name=names[names.length-1];//取最后的一个，就是图片名称
				
				var that=this;
				let image = new Image()
				image.setAttribute('crossOrigin', 'anonymous')
				image.src = url
				image.onload = () => {
				  let canvas = document.createElement('canvas')
				  canvas.width = image.width
				  canvas.height = image.height
				  let ctx = canvas.getContext('2d')
				  ctx.drawImage(image, 0, 0, image.width, image.height)
				  canvas.toBlob((blob) => {
					let url = URL.createObjectURL(blob)
					that.download(url,name)
					// 用完释放URL对象
					URL.revokeObjectURL(url)
				  })
				}
			  },
			download(href, name) {
			  let eleLink = document.createElement('a')
			  eleLink.download = name
			  eleLink.href = href
			  eleLink.click()
			  eleLink.remove()
			  this.$toast.add({
			  	severity: 'success',
			  	summary: '下载提示',
			  	detail: '图片下载完成',
			  	life: 2000
			  });
			},
			//一键复制文本
			copyLink() {
				// var explain_id='img_explain_'+j;
				document.getElementById('invote_link').select();
				document.execCommand("Copy");
				this.$toast.add({
					severity: 'success',
					summary: '复制提示',
					detail: '分享链接已复制到剪贴板',
					life: 2000
				});
			 },
			//生成用户的邀请链接和二维码
			createInvoteThing(){
				this.createInvotePathAndQrcode();
			},
			async createInvotePathAndQrcode(){
				var data = new URLSearchParams();
				var url = '/member/create-invote-path-and-qrcode';
				var resp = await this.networkService.postService(url, data);
				if(resp.code==200){
					this.user_info.account_info.iv_qr=resp.data.iv_qr;
					this.user_info.account_info.iv_path=resp.data.iv_path;
				}
			},
			//修改邮箱
			changeEmail(email_type){
				this.change_email_type=email_type;
				this.show_change_email=true;
			},
			async getUserInfo() {
				//cookie存在之后再检查登录态
				var data = new URLSearchParams();
				var url = '/member/info';
				var resp = await this.networkService.postService(url, data);
				if(resp.code==200){
					this.user_info=resp.data.info;
				}
			},
			
			//用验证码和邮箱登录
			login(){
				var vecode=document.getElementById('vericode').value;
				if (vecode.length!=6 || vecode.replace(/[^0-9]/ig,"").length!=6){
					this.showEmainError('请输入六位数字验证码');
					return;
				}
				//再次验证邮箱是否正确
				var user_email=document.getElementById('user_email').value;
				this.tryLogin(user_email,vecode);
				// if(user_email.endsWith('.edu.cn') || user_email.endsWith('.edu'))
				// {
				// 	this.tryLogin(user_email,vecode);
				// }else{
				// 	this.showEmainError('请输入正确的校园邮箱(以edu.cn或者edu结尾)');
				// }
			},
			async tryLogin(user_email,veri_code) {
				this.processing = true;
				var data = new URLSearchParams();
				data.append("new_email",user_email);
				data.append("email_type",this.change_email_type);
				data.append("veri_code",veri_code);
				var url = '/member/change-email';
				// console.log("请求开始");
				var resp = await this.networkService.postService(url, data);
				if(resp.code==200){
					//关掉登录页面
					localStorage.setItem('Authorization',resp.data.cookie);
					this.show_change_email=false;
					this.$toast.add({
						severity: 'success',
						summary: '修改成功',
						life: 3000
					});
					var _this=this;
					setTimeout(() => {
						//uni.showToast({title: '验证码已发送',icon:"none"});
						_this.getUserInfo();
					}, 1000)
				}else{
					this.showEmainError(resp.msg);
				}
				this.processing = false;
			},
			//邮箱错误提示
			showEmainError(msg){
				this.$toast.add({
					severity: 'error',
					summary: '失败',
					detail: msg,
					life: 4000
				});
			},
			//从云端获取验证码
			getVerifyCode(){
				var user_email=document.getElementById('user_email').value;
				this.email=user_email;
				this.getVeriCode(user_email);
			},
			//获取验证码
			async getVeriCode(user_email) {
				this.processing = true;
				var data = new URLSearchParams();
				data.append("new_email",user_email);
				var url = '/member/get-change-email-vericode';
				// console.log("请求开始");
				var resp = await this.networkService.postService(url, data);
			
				this.show=false;
				if(resp.code==200){
					this.$toast.add({
						severity: 'success',
						summary: '成功',
						detail: resp.msg,
						life: 3000
					});
					var _this=this;
					setTimeout(() => {
						//uni.showToast({title: '验证码已发送',icon:"none"});
						_this.setTimer();
					}, 1000)
				}else{
					this.show=true;
					this.showEmainError(resp.msg);
				}
				this.processing = false;
			},
			//倒计时
			setTimer() {
				let holdTime = 19,
					_this = this;
				_this.vecode_time = "19"
				_this.Timer = setInterval(() => {
					if (holdTime <= 0) {
						_this.vecode_time = "20";
						_this.show=true;
						clearInterval(_this.Timer);
						return;
					}
					_this.vecode_time =holdTime;
					holdTime--;
				}, 1000)
			},
		}
	}
</script>
<style scoped lang="scss">
	.down-img-button{
		// color: var(--primary-color);
		color: #FFFFFF;
		padding: 5px;
		border-radius: 3px;
		background-color:var(--primary-color) ;
		margin-left: 20px;
		font-weight: 800;
		transform: rotate(90deg);
	}
</style>
